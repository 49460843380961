import React from 'react'
import PropTypes from 'prop-types'

const PageBannerImage = props => (
	<section
		className="pageBannerImage hero"
		style={{
			backgroundImage: `url('${props.img}')`,
			backgroundPosition: `${props.imgPosition}`,
		}}
	>
		{/* <img
			className="background-img"
			src={props.img}
			style={{ backgroundPosition: `${props.imgPosition}` }}
		/> */}
		<div className="background-gradient" />
		<div className="hero-body">
			<div className="container font-display">
				<h1 className="title is-size-2">
					{props.title ? props.title : 'Page Title'}
				</h1>
				{props.title2 && (
					<h2 className="title is-size-4 is-spaced has-text-grey-dark">
						{props.title2 ? props.title2 : 'Page Title 2'}
					</h2>
				)}
				{props.subtitle && (
					<p className="subtitle is-spaced">
						{props.subtitle ? props.subtitle : 'Page Subtitle'}
					</p>
				)}
			</div>
		</div>
	</section>
)

PageBannerImage.propTypes = {
	title: PropTypes.any,
	title2: PropTypes.any,
	subtitle: PropTypes.any,
	img: PropTypes.any,
	imgPosition: PropTypes.any,
}

export default PageBannerImage
