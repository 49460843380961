import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import PageBannerImage from '../../components/PageBannerImage'
import PageBannerText from '../../components/PageBannerText'
import MineralChlorinatorBanner from '../../images/products/MineralSwimV2_BENEFITS_Generator&Purifier_1920x1000.jpg'
import { Link } from 'gatsby'
import Holder from '../../images/image.png'
const MineralChlorinator = () => (
	<Layout style={{ paddingBottom: '1rem' }}>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Mineral Generator Advantages: Elevate Your Pool Experience | MineralSwim</title>
			<meta
				name="description"
				content="Natures most powerful sanitiser. Up to 3000x more powerful than other sanitation methods & reduces chlorine needs up to 80%. Minerals in the water have many health benefits and is suitable for sensitive skin. "
			/>
			<meta
				name="keywords"
				content="mineral pool Generator, mineral generator"
			/>
			<link rel="canonical" href="https://mineralswim.com/benefits/mineral-chlorinator/" />
		</Helmet>
		<main  className="bg-white relative">
			<header className='w-screen absolute main-page-header bg-center z-10'
						>
							<div className='mx-auto max-w-screen-xl h-full grid grid-cols-6 lg:grid-cols-12 lg:py-10 px-5 md:px-10'>						
							<div className='hidden lg:flex col-span-6'></div>
								<div className='text-center lg:text-left col-span-6 h-full flex flex-col justify-center md:gap-5 lg:gap-0'>
								<div className='bg-white bg-opacity-40 p-5 rounded-2xl '>
									<h1 className='text-black text-2xl md:text-4xl font-semibold'>Eco-Friendly</h1>
									<div className='flex w-full justify-center items-center lg:justify-start'>
									<div className='w-20 md:w-16 h-1 mb-4 mt-4 md:mt-2 border-2 border-black'/>	
									</div>
									<p className='text-black md:text-base leading-6'>Maintaining a pool often involves harsh chemicals. Mineral Swim™ offers an eco-friendly alternative, reducing chemical reliance, water wastage, and costs.
										</p>				
										<div className='w-full flex justify-center lg:justify-start mt-7 md:mt-10'>			              
										<Link to="/s/?page=benefits&article=mineral-chlorinator">             
									<p className='text-white text-base font-bold bg-gray-800 px-8 rounded-lg py-1 hover:bg-gray-200 hover:text-black cursor-pointer uppercase'>Learn more</p>	
									</Link>			
									</div>	
									</div>								
									</div>                
							</div>
								</header>
				<div className='top-0 bg-cover bg-right lg:bg-center z-0' style={{
                backgroundImage: `url(${MineralChlorinatorBanner})`,
								backgroundAttachment: 'fixed',
								position: 'relative',
								height: '90vh',
            }}
						data-scroll
      data-scroll-speed="-10"
						>
							
								</div>
								<div className='relative -mt-24 md:-mt-24 lg:-mt-40 pt-40 pb-20 bg-white z-10'>
				<section  id="main-content" className="max-w-screen-xl font-display text-base mx-auto mb-20 text-justify md:text-left px-8 md:px-10 grid grid-cols-1 md:grid-cols-2 gap-10">
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Eco-Friendly Pool Sanitation</h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>Maintaining a clean and healthy pool can often involve harsh chemicals that are hard on the environment, your pool equipment, and water resources. Mineral Swim™ offers an innovative and eco-friendly alternative that reduces reliance on these chemicals while providing numerous benefits. From being Climate Care Certified to reducing water wastage and maintenance costs, Mineral Swim™ is the sustainable choice for pool owners who care about the environment.</p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/51022794744ecofriendlybodycopy-_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=V6nOZuLS1iNCPAx1hc%2Bcv%2FKZZD3CeAj1EPOGurqVUegJBKCjMhnH9T0tehGxC20K%2FB7ILs1iygqnSCKINEof%2BEgkiXcTh5KGNoInvQFxJPWfWHhCQ1o7YmxPoVB%2FVGlH16JO%2F%2BuIMMSgx4U%2F2WIAo9z43atNm4lsJPmoMQFa24SRzMjAr%2BfzeFf7rCaJcYpKJT31JB0UPUA9iaDKvK6TtR5FU3BpHFDb75hfb4xSx7PLjH4Ea0p%2BUT3EjmnsxXsXsOQnoJ9Ku%2BWQq8Ks24%2BbViWKc8Sf2Tj092BZ3OebVcGjYunjURGZFJs4ZTjrWS33lrIqvCuD3lS%2FXWjOO7DUow%3D%3D" alt='EcoFriendly' className='w-full h-72 border object-cover rounded-2xl'/>	
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Environmentally Friendly System</h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>Mineral Swim™ is an eco-friendly pool system that significantly reduces the reliance on harsh chemicals. Using natural Dead Sea Minerals for sanitation ensures a gentler approach to keeping your pool clean and safe. The Mineral SwimTM system also incorporates natural ozone-to-oxygen purification, which breaks down contaminants in the water into oxygen, leaving no harmful residues behind.</p>
					</div>		
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/12353947297environmentallyfriendlysystem_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=jpQiwQv1ZhsEDMQVOEi2pet2ay50UT2yBU1nqBebfQipdKJtSAbLCKKP%2FW5drMD4Gu%2BzLqlbq7Ke2UQWt6vtmxMCGt%2BKvPprYADpriBftwkFU%2BJMVXp%2Bg9JtqL4ACMz%2F%2F%2B9Begn2ok7ji903hRGrsk1MWRpZ1TH98dj%2BVud%2FqIElXPY%2B0m1tV6tavegB%2BlWj0t4SbKbpIbtZAY5jpnLrlQfHsqeClo99YDbpS24naSC91S%2FLHVBIH7iW7mU5MjtrXHZQw00%2FH4GCQ8eoICfGmdK8dqsxdegDCBdPXSknaxQkAyKysjqIIHaIilN6QrlkrjjtjFANn80hF%2Fj3L0Lpew%3D%3D" alt='Environtment Friendly' className='w-full h-72 border object-cover rounded-2xl'/>								
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Climate Care Certified</h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>Mineral Swim™ is proud to be Climate Care Certified by the Swimming Pool and Spa Association of Australia (SPASA). This certification measures water and energy efficiency, setting the benchmark for environmental sustainability in swimming pools in Australia. By choosing Mineral Swim™, you're supporting a system that prioritises the health of our planet.</p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/71387327227climatecarecertified_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=agrBXBPnfaHWZQlONTW9bRBEkucNv%2BUAO%2BylMhqs8D0ErphdXDc%2FJIyKksT1NgcFxCFIThEJC2qWoolRSC2hFWoqt9h6fMlmeAUbmA88Dxg5Zjh1gwAhstpQjNMBBAXiyQ1VyCKm89Xl11qWcMQrZgQMNpMqw8pVFN5raEq%2Fzw%2B5PU5hnT11Zye8xMl1Qn5JZZirs%2FZtTUoCvmHv1O7SXzf9ud1k7FYEGeNy6desmtmjwSYrKCHIFWR%2Bg%2FGY0TwawJ9C6KOCIzKkspx5ZJoumKLV472GwLQYDeSQmXWiFZtVOXc47lQOEPA%2FcjV4dyiVqrkp3Noy2Cx0Tak27K6prw%3D%3D" alt='Climate Care Certified' className='w-full h-72 border object-cover rounded-2xl'/>	
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Reduces Water Wastage</h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>Magnesium, a key mineral in the Mineral Swim™ system, is an efficient flocculant. It improves water clarity, reducing the need for backwashing. With fewer chemicals in the water it can be safely diluted and reused for garden irrigation, promoting water conservation.</p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/3745921585reduceswaterwastage_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=dTfPUffS3xk%2F5vahfaHVkTLsXwhdVpXeIyQNGEVQewZwCioPZBRcnLXy5P3kkaTPwyfoRfIqFw15mUV9RBqAoi8%2FYx8NQFpjn9qSSEZzwuRJqF5QnaDATxFfR5SK7EGHofmjynyrKRaA%2BbIM4Eh3SI1St6EvT5qUAAfbz5LB3esl5U3AJiaHSEv9iLeSVoe3v8boOAgkrJbFyAeZh4RB9pEN0evMD56iy5xMmbvvIG%2BqxTvfGE13n0Gx24HXCzVMbVCtPXbFZw%2FQTVwsaGvQs1qL6duKbkOwf0hd2k7uUr7coYeG1jA2hnS8BlH1JyTjWQZKkHVqjZ8%2FNUcgXujN1g%3D%3D" alt='Reduces Water Wastage' className='w-full h-72 border object-cover rounded-2xl'/>	
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Reduces Maintenance Costs</h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>A Mineral Swim™ pool has fewer harsh chemicals and salt, which helps prolong the life of your pool equipment. Reducing chemical usage and salt levels leads to fewer replacements, repairs, and maintenance, ultimately saving you money and reducing environmental impact.</p>
					<br/>
					<br/>
					<p>By incorporating Mineral Swim™ into your pool maintenance routine, you can enjoy a cleaner, safer swimming experience while also contributing to a healthier planet. Choose Mineral Swim™ for an eco-friendly, cost-effective, and sustainable pool solution.</p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/73684643603reducesmaintenancecosts_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=cVgU26YG3rGS0SrTQ4wMhd9ACqqrWr6%2B%2F1AQ5nKxj77pZtigje%2FwjUW9tcx038VbFeMu%2FjEeR5Iyv%2BnujdSxqzbhgYF71gwYJ5Il5oqu%2BMgDW%2BIfz8ZZ0jGXggrrj8hiQMmHujCkkPpm7V2nK%2FABCuibvAdmnznu4WpcjSddHo7PzHovxD7QShYeb7WRQen0YoI8RfM9lnh%2FDue6dMaNBKh4%2F5YhgsJVJDWPCsmexDTAU%2BE7FxE8LKVFa7LD1Qy4CsHdRNsOGNGg2%2F3KIucCZihESbvsJ59TnXs2Dm4cLtALI0yI5tbkZ%2FPZemQGr0opyAlhPnxDHUfdIvDviWqIXQ%3D%3D" alt='Reduce Maintenance Cost' className='w-full h-72 border object-cover rounded-2xl'/>	
				</section>
				<div className="flex flex-col sm:flex-row gap-4 items-center justify-center lg:flex-1 font-display text-base font-semibold uppercase">
					<Link
					to="/benefits"
									className="cursor-pointer flex items-center justify-center  w-48 py-1 border border-transparent rounded-md shadow-sm text-base font-medium text-gray-800 bg-gray-100 hover:bg-gray-300 whitespace-nowrap"
								>
									Back to Benefits
								</Link>
						<Link
							to="https://www.maytronics.com.au/shop?brand=Mineral%2520Swim"
							className="whitespace-nowrap inline-flex items-center justify-center w-48 py-1 border border-transparent rounded shadow-sm bg-blue-500 hover:bg-blue-400 text-white"
						>
							Explore Products
						</Link>
					</div>
				</div>
				</main>
	</Layout>
)

export default MineralChlorinator